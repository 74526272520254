import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaTachometerAlt, FaPassport, FaSuitcaseRolling, FaClipboardList, FaHistory, FaUserCircle } from 'react-icons/fa';
// import '../styles/Sidebar.css';  // Import the CSS file

const Sidebar = () => {

    return (
        <div className="sidebar">
            {/* Logo placeholder */}
            <div className="sidebar-logo">
                <center><h2>Visa Centrum</h2></center> {/* Replace this with an <img> tag when you have a logo */}
            </div>
            <nav className="sidebar-nav">
                <ul>
                    <li>
                        <NavLink to="/">
                            <FaTachometerAlt /> Dashboard
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/active-applications">
                            <FaPassport /> Active Applications
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/travellers">
                            <FaSuitcaseRolling /> Travellers
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/past-applications">
                            <FaHistory /> Past Applications
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="/visa-requirements">
                            <FaClipboardList /> Visa requirements
                        </NavLink>
                    </li>
                </ul>
            </nav>
            <div className="sidebar-account">
                <NavLink to="/account">
                    <FaUserCircle /> Account
                </NavLink>
            </div>
        </div>
    );
};

export default Sidebar;
