import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaChevronLeft, FaChevronRight, FaPlaneDeparture, FaGlobeAfrica, FaPassport, FaArrowLeft } from 'react-icons/fa';
import { MdGroups } from "react-icons/md";
import './VisaApplication.css';
import { backendToFrontEndStatusMapping } from '../../config/constants'

const VisaApplicationGroup = () => {
    const { id } = useParams();
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [visaApplicationGroup, setVisaApplicationGroup] = useState(null);
    const [visaApplications, setVisaApplications] = useState(null);
    const [travelDateFrom, setTravelDateFrom] = useState(new Date());
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const navigate = useNavigate();

    useEffect(() => {
        const fetchVisaApplicationGroupDetails = async () => {
            try {
                const applicationData = await apiService.get(`/travelAgent/visa-application-group/${id}`);
                setVisaApplicationGroup(applicationData);
                setVisaApplications(applicationData.visaApplicationIds);
                setTravelDateFrom(new Date(applicationData.travelDateFrom));
                setTotalCount(applicationData.visaApplicationIds.length);
            } catch (error) {
                console.error('Error fetching visa application details:', error);
            }
        };

        fetchVisaApplicationGroupDetails();
    }, [id]);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / limit)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleRowClick = (visaApplicationId) => {
        navigate(`/visa-application/${visaApplicationId}`);
    }

    if (!visaApplicationGroup) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-container">
            <button className="back-button" onClick={() => navigate('/active-applications')}>
                <FaArrowLeft /> Back
            </button>
            <div className="main-header">
                <div className="group-details">
                    <div className="icon-with-text">
                        <MdGroups className="group-icon" />
                        <div>
                            <h2>{visaApplicationGroup.groupName}</h2>
                        </div>
                    </div>
                    <div className='group-travel-details'>
                        <div className='icon-with-text'>
                            <FaGlobeAfrica className="icon" />
                            <p>{visaApplicationGroup.visaTypeId.countryId.name}</p>
                        </div>
                        <div className='icon-with-text'>
                            <FaPassport className="icon" />
                            <p>{visaApplicationGroup.visaTypeId.visaName}</p>
                        </div>
                        <div className='icon-with-text'>
                            <FaPlaneDeparture className="icon" />
                            <p>{`${travelDateFrom.getDate()}-${months[travelDateFrom.getMonth()]}-${travelDateFrom.getFullYear()}`}</p>
                        </div>

                    </div>
                </div>
                <div className="application-status-details">
                    <div className="phase">
                        <p>Status</p>
                        <div className="phase-status">{backendToFrontEndStatusMapping[visaApplicationGroup.externalStatus]}</div>
                    </div>
                </div>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Traveller Name</th>
                        <th>Application Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        visaApplications.slice((currentPage - 1) * limit, (currentPage - 1) * limit + limit).map((app, index) => (
                            <tr key={app._id} onClick={() => handleRowClick(app._id)}>
                                <td>{app.travellerId ? `${app.travellerId.givenName} ${app.travellerId.lastName}` : `Traveller ${index + 1}`}</td>
                                <td>{backendToFrontEndStatusMapping[app.externalStatus]}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / limit)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / limit)}
                >
                    Next <FaChevronRight />
                </button>
            </div>
        </div>
    );
};

export default VisaApplicationGroup;
