import React, { useState } from 'react';

const PastApplications = () => {

    return (
        <></>
    );
};

export default PastApplications;
