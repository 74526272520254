import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import ActiveApplications from './components/ActiveApplications';
import Travellers from './components/Travellers';
import PastApplications from './components/PastApplications';
import VisaRequirements from './components/VisaRequirements';
import VisaApplication from './components/VisaApplication';
import VisaApplicationGroup from './components/VisaApplicationGroup';
import DocumentPreview from './components/DocumentPreview';
import Account from './components/Account';
import Login from './pages/Login';
import Header from './components/Header';
import { UserProvider } from './context/UserContext';
import { Toaster } from 'react-hot-toast';

const AppContent = () => {
  const location = useLocation();

  return (
    <div className="app">
      {/* Conditionally render Sidebar only if the path is not /login */}
      {location.pathname !== '/login' && <Sidebar />}
      <div className={`main-content ${location.pathname === '/login' ? 'full-width' : ''}`}>
        {location.pathname !== '/login' && <Header />}
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/active-applications" element={<ActiveApplications />} />
          <Route path="/visa-application-group/:id" element={<VisaApplicationGroup />} />
          <Route path="/visa-application/:id" element={<VisaApplication />} />
          <Route path="/visa-application/:id/documents" element={<DocumentPreview />} />
          <Route path="/travellers" element={<Travellers />} />
          <Route path="/past-applications" element={<PastApplications />} />
          <Route path="/visa-requirements" element={<VisaRequirements />} />
          <Route path="/account" element={<Account />} />
        </Routes>
      </div>
      <Toaster />
    </div>
  );
};

const App = () => (
  <Router>
    <UserProvider>
      <AppContent />
    </UserProvider>
  </Router>
);

export default App;