// src/services/apiService.js
import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
    (config) => {
        // Exclude these URLs from adding the Authorization header
        const excludeAuthUrls = [
            '/travelAgent/send-otp',
            '/travelAgent/authenticate',
            '/travelAgent/signup',
        ];

        // Check if the request URL should be excluded
        const isExcluded = excludeAuthUrls.some(url => config.url.includes(url));

        // If not excluded, add the Authorization header
        if (!isExcluded) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const apiService = {
    get,
    post,
    put,
    delete: _delete,
    upload
};

function handleResponse(response) {
    if (response.status < 200 || response.status > 300) {
        const error = response.statusText;
        return Promise.reject(error);
    }
    return response.data;
}

function handleError(error) {
    console.error('API call failed. ', error);
    throw error;
}

function get(url) {
    return axiosInstance.get(url)
        .then(handleResponse)
        .catch(handleError);
}

function post(url, body) {
    return axiosInstance.post(url, body)
        .then(handleResponse)
        .catch(handleError);
}

function put(url, body) {
    return axiosInstance.put(url, body)
        .then(handleResponse)
        .catch(handleError);
}

function _delete(url) {
    return axiosInstance.delete(url)
        .then(handleResponse)
        .catch(handleError);
}

// Upload method for handling FormData
function upload(url, formData) {
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',  // Override default content type for upload
        },
    })
        .then(handleResponse)
        .catch(handleError);
}