import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../services/apiService';
import Autosuggest from 'react-autosuggest';

const Login = () => {
    const [phone, setPhone] = useState('');
    const [otp, setOtp] = useState('');
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [authFlow, setAuthFlow] = useState(''); // State to manage authFlow
    const [errorMessage, setErrorMessage] = useState('');

    // Registration fields
    const [businessName, setBusinessName] = useState('');
    const [pointOfContactName, setPointOfContactName] = useState('');
    const [state, setState] = useState('');
    const [states, setStates] = useState([]);
    const [suggestions, setSuggestions] = useState([]);


    const navigate = useNavigate();

    // useEffect(() => {
    //     // Fetch the list of states when the component mounts
    //     const fetchStates = async () => {
    //         try {
    //             const response = await apiService.get('/travelAgent/public/states');
    //             setStates(response.states);
    //         } catch (error) {
    //             setErrorMessage('Failed to load states. Please try again.');
    //         }
    //     };

    //     fetchStates();
    // }, []);

    const handleSendOtp = async () => {
        try {
            const response = await apiService.post('/travelAgent/auth/send-otp', { phone });
            setAuthFlow(response.authFlow);
            setIsOtpSent(true);
        } catch (error) {
            setErrorMessage(error.message || 'Failed to send OTP. Please try again.');
        }
    };

    const handleVerifyOtp = async () => {
        try {
            if (authFlow === 'login') {
                const data = await apiService.post('/travelAgent/auth/verify-otp', { phone, otp });
                localStorage.setItem('token', data.token);
                navigate('/');
            } else if (authFlow === 'signup') {
                const registrationData = {
                    businessName,
                    pointOfContactName,
                    phone,
                    otp,
                };
                const data = await apiService.post('/travelAgent/auth/signup', registrationData);
                localStorage.setItem('token', data.token);
                navigate('/');
            }
        } catch (error) {
            setErrorMessage(error.message || 'Invalid OTP or Registration Failed. Please try again.');
        }
    };

    // Autosuggest functions
    const getSuggestions = (value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        return inputLength === 0 ? [] : states.filter(
            state => state.name.toLowerCase().includes(inputValue)
        );
    };

    const getSuggestionValue = (suggestion) => suggestion.name;

    const renderSuggestion = (suggestion) => (
        <div>
            {suggestion.name}
        </div>
    );

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onChange = (event, { newValue }) => {
        setState(newValue);
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>{authFlow === 'signup' ? 'Sign Up' : 'Login'}</h2>
                {errorMessage && <p className="error-message">{errorMessage}</p>}
                {!isOtpSent ? (
                    <div>
                        <input
                            type="text"
                            placeholder="Enter Mobile Number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                        <button onClick={handleSendOtp}>Send OTP</button>
                    </div>
                ) : (
                    <div>
                        {authFlow === 'signup' && (
                            <div>
                                <input
                                    type="text"
                                    placeholder="Business Name"
                                    value={businessName}
                                    onChange={(e) => setBusinessName(e.target.value)}
                                />
                                <input
                                    type="text"
                                    placeholder="Point of Contact Name"
                                    value={pointOfContactName}
                                    onChange={(e) => setPointOfContactName(e.target.value)}
                                />
                                {/* <Autosuggest
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={onSuggestionsClearRequested}
                                    getSuggestionValue={getSuggestionValue}
                                    renderSuggestion={renderSuggestion}
                                    inputProps={{
                                        placeholder: 'State',
                                        value: state,
                                        onChange: onChange
                                    }}
                                /> */}
                            </div>
                        )}
                        <input
                            type="text"
                            placeholder="Enter OTP"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        <button onClick={handleVerifyOtp}>
                            {authFlow === 'signup' ? 'Sign Up' : 'Login'}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Login;
