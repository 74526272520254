import React, { useContext, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import TravelAgentActivationModal from '../TravelAgentActivationModal'; // Import the modal component
import './Header.css';

const Header = () => {
    const { user } = useContext(UserContext); // Get user information from the context
    const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal open state

    if (!user) {
        return null; // Return null if user data is not yet available
    }

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="header">
                <div className="header-left">

                </div>
                <div className="header-right">
                    {(user.travelAgent.approved === false && user.travelAgent.activationSubmitted === false) && (
                        <button className="activate-account-button" onClick={openModal}>
                            Activate Account
                        </button>
                    )}
                    <span className="account-balance">Balance: ₹ {user.wallet.balance}</span>
                    <span className="travel-agent-name">{user.travelAgent.businessName}</span>
                    <span className="avatar">A</span>
                </div>
            </div>

            {isModalOpen && (
                <TravelAgentActivationModal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    travelAgent={user.travelAgent} // Pass travel agent info to the modal
                />
            )}
        </>
    );
};

export default Header;
