import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaRegTrashAlt, FaUpload, FaArrowLeft } from 'react-icons/fa';
import './DocumentPreview.css';
import { toast } from 'react-hot-toast';

const DocumentPreview = () => {
    const { id } = useParams();
    const [documents, setDocuments] = useState([]);
    const [visaApplicationId, setVisaApplicationId] = useState('');
    const [showTooltip, setShowTooltip] = useState(null); // Store the document ID for which the tooltip is shown
    const [draggingOver, setDraggingOver] = useState(null); // To track if dragging over a specific docGroup
    const navigate = useNavigate();

    const fetchDocuments = async () => {
        try {
            const response = await apiService.get(`/travelAgent/visa-application/${id}?includeSignedUrls=1&includeDocumentComments=1`);
            setDocuments(response.visaApplicationDocuments);
            setVisaApplicationId(response.visaApplication._id);
            // Simulating document id from URL or state (adjust accordingly to get actual clicked document)
            // setCurrentDocumentId("docIdToScrollTo");
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };

    useEffect(() => {
        // Fetch the document details from the API
        fetchDocuments();
    }, [id]);

    // useEffect(() => {
    //     // Scroll to the clicked document if available
    //     if (currentDocumentId && documentRefs.current[currentDocumentId]) {
    //         documentRefs.current[currentDocumentId].scrollIntoView({ behavior: 'smooth' });
    //     }
    // }, [currentDocumentId]);

    const toastifyDeleteFailure = () => {
        toast.error('Error Deleting. Try again!');
    }

    const toastifyUploadFailure = () => {
        toast.error('Error Uploading. Try again!');
    };

    const handleDelete = (documentId) => {
        console.log("handleDelete")
        setShowTooltip(null);

        try {
            const deletePromise = apiService.delete(`/travelAgent/visa-application/delete-document/${documentId}`);

            deletePromise.then(async () => {
                fetchDocuments();
            })

            toast.promise(deletePromise, {
                loading: 'Deleting...',
                success: 'Delete successful!',
                error: 'Delete failed! Try again.'
            });
        } catch (error) {
            console.error('Error deleting documents:', error);
            toastifyDeleteFailure();
        }
    }

    const handleTooltip = (docId) => {
        // Toggle the tooltip visibility
        console.log("handleTooltip")
        setShowTooltip(showTooltip === docId ? null : docId);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (event.target.closest(".tooltip"))
                return;

            if (showTooltip) {
                setShowTooltip(null); // Hide tooltip when clicking outside
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showTooltip]);

    const handleDragOver = (e, docGroup) => {
        e.preventDefault();
        setDraggingOver(docGroup._id); // Track which document group is being dragged over
    };

    const handleDrop = async (e, docGroup) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        setDraggingOver(null);

        if (files.length) {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/travelAgent/upload/visa-application/${docGroup._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchDocuments();
                })

                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    const handleFileSelect = async (e, docGroup) => {
        const files = e.target.files;

        if (files.length) {
            const formData = new FormData();
            for (const file of files) {
                formData.append('files', file);
            }

            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/travelAgent/upload/visa-application/${docGroup._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchDocuments();
                })

                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    const renderDocument = (doc, docGroup) => {
        return (
            <div className="document-item-preview">
                {doc.mimeType.includes('pdf') ?
                    <>
                        <div className="document-pdf">
                            <iframe src={doc.preSignedUrl}></iframe>
                        </div>
                        {docGroup.reviewStatus !== 'Approved' && <div className="delete-icon-div">
                            <FaRegTrashAlt className="delete-icon" onClick={() => handleTooltip(doc._id)} />
                        </div>}
                        {showTooltip === doc._id && (
                            <div className="tooltip">
                                <p>Are you sure you want to delete this document?</p>
                                <div className="tooltip-buttons">
                                    <button className="btn-yes" onClick={() => handleDelete(doc._id)}>Yes</button>
                                    <button className="btn-no" onClick={() => setShowTooltip(null)}>No</button>
                                </div>
                            </div>
                        )}
                    </>
                    : doc.mimeType.includes('image') ?
                        <>
                            <img src={doc.preSignedUrl} className="document-image" />
                            {docGroup.reviewStatus !== 'Approved' && <div className="delete-icon-div">
                                <FaRegTrashAlt className="delete-icon" onClick={() => handleTooltip(doc._id)} />
                            </div>}
                            {showTooltip === doc._id && (
                                <div className="tooltip">
                                    <p>Are you sure you want to delete this document?</p>
                                    <div className="tooltip-buttons">
                                        <button className="btn-yes" onClick={() => handleDelete(doc._id)}>Yes</button>
                                        <button className="btn-no" onClick={() => setShowTooltip(null)}>No</button>
                                    </div>
                                </div>
                            )}
                        </>
                        :
                        <FaUpload className="upload-icon" />
                }

            </div>)
    };

    return (
        <div className="main-container">
            <button className="back-button" onClick={() => navigate(`/visa-application/${visaApplicationId}`)}>
                <FaArrowLeft /> Back
            </button>
            <div className="document-header">
                <h2>Document Preview</h2>
            </div>
            <div className="document-list">
                {documents.map((docGroup, index) => (
                    <div
                        key={index}
                        className={`document-group ${draggingOver === docGroup._id ? 'drag-over' : ''}`}
                        onDragOver={(e) => handleDragOver(e, docGroup)}
                        onDrop={(e) => handleDrop(e, docGroup)}
                    >
                        <h3>{docGroup.documentChecklistItemId.documentTitle} [{docGroup.reviewStatus}]</h3>

                        {docGroup.latestComment && docGroup.reviewStatus == 'Needs Attention' && (
                            <div className="comment-section">
                                <p><strong>Comment:</strong> {docGroup.latestComment.commentText}</p>
                                <p><strong>Timestamp:</strong> {new Date().toLocaleString()}</p>
                            </div>
                        )}

                        <div className="doc-preview-upload-pill">
                            <FaUpload className="doc-preview-upload-icon" />
                            <span>Upload</span>
                            <input
                                type="file"
                                multiple
                                accept=".pdf,.png,.jpg,.jpeg,.gif"
                                onChange={(e) => handleFileSelect(e, docGroup)}
                                className="file-input"
                            />
                        </div>
                        {docGroup.uploadedDocumentIds.length ? docGroup.uploadedDocumentIds.map((doc, docIndex) => (
                            <div key={docIndex}>
                                {renderDocument(doc, docGroup)}
                            </div>
                        )) :
                            <div className='document-item-preview'>
                                <FaUpload className="upload-icon" />
                            </div>
                        }
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DocumentPreview;
