import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/apiService'; // Assuming you have apiService for API calls
import { toast } from 'react-hot-toast';
import './Account.css';

const Account = () => {
    const [activeTab, setActiveTab] = useState('profile');
    const [profileData, setProfileData] = useState({
        businessName: '',
        fullAddress: '',
        state: '',
        gstin: ''
    });
    const [currentBalance, setCurrentBalance] = useState(0);
    const [transferData, setTransferData] = useState({
        amount: '',
        transactionDate: new Date().toISOString().split('T')[0], // Default to today
        transactionRefNum: '',
        // screenshot: null
    });
    const [pendingTransactions, setPendingTransactions] = useState([]); // To store pending transactions

    const bankInfo = 'Bank Name: XYZ Bank, Account No: 1234567890, IFSC: XYZB123456';

    const fetchAccountInfo = async () => {
        try {
            const response = await apiService.get('/travelAgent/account');
            const { businessName, fullAddress, state, gstin, currentBalance } = response.travelAgent;
            setProfileData({ businessName, fullAddress, state, gstin });
            setCurrentBalance(response.travelAgent.wallet.balance);
        } catch (error) {
            console.error('Error fetching account info:', error);
        }
    };

    const fetchPendingTransactions = async () => {
        try {
            const response = await apiService.get('/travelAgent/wallet/pending-transactions');
            setPendingTransactions(response.pendingTransactions); // Store pending transactions
        } catch (error) {
            console.error('Error fetching pending transactions:', error);
        }
    };

    useEffect(() => {
        // Fetch user profile and wallet data from the API when the component loads
        fetchAccountInfo();
        fetchPendingTransactions();
    }, []);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const handleProfileChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleTransferChange = (e) => {
        const { name, value } = e.target;
        setTransferData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleScreenshotUpload = (e) => {
        setTransferData((prevData) => ({
            ...prevData,
            screenshot: e.target.files[0]
        }));
    };

    const handleTransferSubmit = async (e) => {
        e.preventDefault();
        // Handle proof upload later
        // const formData = new FormData();
        // formData.append('amount', transferData.amount);
        // formData.append('transactionDate', transferData.transactionDate);
        // formData.append('transactionRefNum', transferData.transactionRefNum);
        // if (transferData.screenshot) {
        //     formData.append('screenshot', transferData.screenshot);
        // }

        try {
            const submitPromise = apiService.post('/travelAgent/wallet/submit-manual-transaction', transferData);

            submitPromise.then(() => {
                fetchPendingTransactions();
                setTransferData({
                    amount: '',
                    transactionDate: new Date().toISOString().split('T')[0],
                    transactionRefNum: '',
                    // screenshot: null
                });
            })

            toast.promise(submitPromise, {
                loading: 'Submitting...',
                success: 'Transaction submitted for review... ',
                error: 'Submit failed! Try again.'
            });

        } catch (error) {
            console.error('Error submitting transfer info:', error);
        }
    };

    const today = new Date().toISOString().split('T')[0];

    return (
        <div className="main-container">
            <div className="tabs">
                <button
                    className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
                    onClick={() => handleTabClick('profile')}
                >
                    Profile
                </button>
                <button
                    className={`tab-button ${activeTab === 'wallet' ? 'active' : ''}`}
                    onClick={() => handleTabClick('wallet')}
                >
                    Wallet
                </button>
            </div>

            {activeTab === 'profile' && (
                <div className="profile-section">
                    <h2>Profile</h2>
                    <form>
                        <div className="profile-form">
                            <div className="form-group">
                                <label>Business Name</label>
                                <input type="text" value={profileData.businessName} disabled />
                            </div>
                            <div className="form-group">
                                <label>Full Address</label>
                                <textarea
                                    name="fullAddress"
                                    value={profileData.fullAddress}
                                    onChange={handleProfileChange}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>State</label>
                                <input
                                    name="state"
                                    type="text"
                                    value={profileData.state}
                                    onChange={handleProfileChange}
                                    disabled
                                />
                            </div>
                            <div className="form-group">
                                <label>GSTIN</label>
                                <input type="text" value={profileData.gstin} disabled />
                            </div>
                        </div>
                    </form>
                </div>
            )}

            {activeTab === 'wallet' && (
                <div className="wallet-section">
                    <h2>Wallet</h2>
                    <p><strong>Current Balance:</strong> ₹{currentBalance}</p>
                    <p><strong>Bank Information for Transferring Funds:</strong></p>
                    <p>{bankInfo}</p>
                    <p>Once you've transferred the funds, please fill in the following form to notify us:</p>
                    <div className='transfer-form'>
                        <form onSubmit={handleTransferSubmit}>
                            <div className="form-group">
                                <label>Amount Transferred</label>
                                <input
                                    name="amount"
                                    type="number"
                                    value={transferData.amount}
                                    onChange={handleTransferChange}
                                    required
                                    max={10000000}
                                />
                            </div>
                            <div className="form-group">
                                <label>Date of Transfer</label>
                                <input
                                    name="transactionDate"
                                    type="date"
                                    value={transferData.transactionDate}
                                    onChange={handleTransferChange}
                                    required
                                    max={today}
                                />
                            </div>
                            <div className="form-group">
                                <label>Transaction Reference Number</label>
                                <input
                                    name="transactionRefNum"
                                    type="text"
                                    value={transferData.transactionRefNum}
                                    onChange={handleTransferChange}
                                    maxLength={100}
                                    required
                                />
                            </div>
                            {/* <div className="form-group">
                                <label>Upload Screenshot</label>
                                <input
                                    name="screenshot"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleScreenshotUpload}
                                />
                            </div> */}
                            <button type="submit">Submit</button>
                        </form>
                        {/* Pending Transactions Table */}
                        {pendingTransactions.length > 0 && (
                            <>
                                <h3>Pending Transactions</h3>
                                <table className="pending-transactions-table">
                                    <thead>
                                        <tr>
                                            <th>Transfer Date</th>
                                            <th>Amount</th>
                                            <th>Transaction Reference Number</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pendingTransactions.map((transaction) => (
                                            <tr key={transaction.transactionRefNum}>
                                                <td>{(new Date(transaction.transactionDate)).toLocaleDateString()}</td>
                                                <td>₹{transaction.amount}</td>
                                                <td>{transaction.transactionRef}</td>
                                                <td>{transaction.status}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Account;
