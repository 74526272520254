import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import { FaUpload, FaUserCircle, FaPlaneDeparture, FaGlobeAfrica, FaPassport, FaFileAlt, FaArrowLeft } from 'react-icons/fa';
import './VisaApplication.css';
import { backendToFrontEndStatusMapping } from '../../config/constants';
import { toast } from 'react-hot-toast';

const VisaApplication = () => {
    const { id } = useParams();
    const [visaApplication, setVisaApplication] = useState(null);
    const [visaApplicationGroup, setVisaApplicationGroup] = useState(null);
    const [documentChecklist, setDocumentChecklist] = useState([]);
    const [travelDateFrom, setTravelDateFrom] = useState(new Date());
    const fileInputRefs = useRef({}); // To reference each file input
    const [dragOver, setDragOver] = useState(null); // Track the document item being dragged over
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const navigate = useNavigate();

    const toastifyUploadSuccess = () => {
        toast.success('Document Uploaded');
    };

    const toastifyUploadFailure = () => {
        toast.error('Error Uploading. Try again!');
    };

    const fetchVisaApplicationDetails = async () => {
        try {
            const applicationData = await apiService.get(`/travelAgent/visa-application/${id}`);
            setVisaApplication(applicationData.visaApplication);
            setDocumentChecklist(applicationData.visaApplicationDocuments);
            setVisaApplicationGroup(applicationData.visaApplicationGroup);
            setTravelDateFrom(new Date(applicationData.visaApplicationGroup.travelDateFrom));
        } catch (error) {
            console.error('Error fetching visa application details:', error);
        }
    };

    useEffect(() => {
        fetchVisaApplicationDetails();
    }, [id]);

    // Handle file selection and upload
    const handleFileSelect = async (files, document) => {
        if (files && files.length > 0) {
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                formData.append('files', files[i]);
            }
            try {
                // Use the upload method in apiService
                const uploadPromise = apiService.upload(`/travelAgent/upload/visa-application/${document._id}`, formData);

                uploadPromise.then(async () => {
                    // Refresh document checklist after upload
                    fetchVisaApplicationDetails();
                })

                // toastifyUploadSuccess();
                toast.promise(uploadPromise, {
                    loading: 'Uploading...',
                    success: 'Upload successful!',
                    error: 'Upload failed! Try again.'
                });
            } catch (error) {
                console.error('Error uploading documents:', error);
                toastifyUploadFailure();
            }
        }
    };

    // Drag and drop handlers
    const handleDragOver = (e, document) => {
        e.preventDefault();
        setDragOver(document._id); // Mark the item being dragged over
    };

    const handleDragLeave = () => {
        setDragOver(null); // Reset when the drag leaves the item
    };

    const handleDrop = (e, document) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        handleFileSelect(files, document);
        setDragOver(null); // Reset drag-over state
    };

    const documentContainerClicked = (document) => {
        if (document.reviewStatus == 'Pending')
            fileInputRefs.current[document._id].click();
        else
            navigate(`/visa-application/${visaApplication._id}/documents`);
    }

    // Render document upload divs or thumbnails
    const renderDocumentItem = (document) => {
        const inputId = `file-input-${document._id}`; // Unique id for each document's input

        return (
            <div
                className={`document-item ${dragOver === document._id ? 'document-item--dragover' : ''}`}
                onClick={() => documentContainerClicked(document)}
                onDragOver={(e) => handleDragOver(e, document)}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, document)}
            >
                {document.uploadedDocumentIds.length === 0 ? (
                    <FaUpload className="upload-icon" />
                ) : (
                    <FaFileAlt className="upload-icon" />
                )}
                <p>{document.documentChecklistItemId.documentTitle}</p>
                <p>
                    <span className={document.uploadedDocumentIds.length === 0 ? 'status-pending' : 'status-uploaded'}>
                        {document.reviewStatus}
                    </span>
                </p>
                {/* Hidden input for file upload */}
                <input
                    type="file"
                    id={inputId}
                    ref={(el) => (fileInputRefs.current[document._id] = el)}
                    style={{ display: 'none' }}
                    multiple
                    accept=".pdf,.png,.jpg,.jpeg,.gif"
                    onChange={(event) => handleFileSelect(event.target.files, document)}
                />
            </div>
        );
    };

    if (!visaApplication) {
        return <div>Loading...</div>;
    }

    return (
        <div className="main-container">
            <button className="back-button" onClick={() => navigate(`/visa-application-group/${visaApplicationGroup._id}`)}>
                <FaArrowLeft /> Back
            </button>
            <div className="main-header">
                <div className="traveller-name">
                    <FaUserCircle className="applicant-icon" />
                    <div>
                        <h2>{visaApplication.applicantName}</h2>
                    </div>
                    <div className='icon-with-text'>
                        <FaGlobeAfrica className="icon" />
                        <p>{visaApplicationGroup.visaTypeId.countryId.name}</p>
                    </div>
                    <div className='icon-with-text'>
                        <FaPassport className="icon" />
                        <p>{visaApplicationGroup.visaTypeId.visaName}</p>
                    </div>
                    <div className='icon-with-text'>
                        <FaPlaneDeparture className="icon" />
                        <p>{`${travelDateFrom.getDate()}-${months[travelDateFrom.getMonth()]}-${travelDateFrom.getFullYear()}`}</p>
                    </div>
                </div>
                <div className="application-status-details">
                    <div className="phase">
                        <p>Status</p>
                        <div className="phase-status">{backendToFrontEndStatusMapping[visaApplication.externalStatus]}</div>
                    </div>
                </div>
            </div>

            <div className="document-checklist">
                {documentChecklist.map((document) => (
                    <div key={document._id} className="document-container">
                        {renderDocumentItem(document)}
                    </div>
                ))}
            </div>

        </div>
    );
};

export default VisaApplication;
