import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext'; // Adjust the import path as needed
import './TravelAgentActivationModal.css';
import { apiService } from '../../services/apiService'; // Assuming you have an apiService for API calls
import { toast } from 'react-hot-toast';

const TravelAgentActivationModal = ({ isOpen, onClose, travelAgent }) => {
    const [formData, setFormData] = useState({
        businessName: travelAgent.businessName,
        pointOfContactName: travelAgent.pointOfContactName,
        pointOfContactNumber: travelAgent.pointOfContactNumber,
        address: '',
        city: '',
        state: '',
        gstin: '',
        gstCertificate: null,
        businessRegistrationProof: null,
        termsAccepted: false,
    });
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const [states, setStates] = useState([]); // State to store list of states

    const gstRegex = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}$/i;

    const udpateUser = async () => {
        try {
            const userData = await apiService.get('/travelAgent/me');
            setUser(userData);
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // If 401, navigate to login
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        // Fetch the list of states when the modal is opened
        const fetchStates = async () => {
            try {
                const response = await apiService.get('/travelAgent/public/states');
                setStates(response.states);
            } catch (error) {
                console.error('Error fetching states:', error);
            }
        };

        fetchStates();
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFileChange = (e) => {
        const { name } = e.target;
        setFormData({
            ...formData,
            [name]: e.target.files[0],
        });
    };

    const handleCheckboxChange = (e) => {
        setFormData({
            ...formData,
            termsAccepted: e.target.checked,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.termsAccepted) {
            alert('You must accept the Terms and Conditions and Privacy Policy');
            return;
        }
        if (formData.gstin && !gstRegex.test(formData.gstin)) {
            alert('Invalid GST Number format.');
            return;
        }

        // Submit form data (e.g., API call)
        const activationRequestPromise = apiService.upload('/travelAgent/account/activation-request', formData);

        toast.promise(activationRequestPromise, {
            loading: "Submitting Activation Request...",
            success: "Activation Submitted",
            error: "Activation Request Failed! Try again!"
        });

        activationRequestPromise.then(() => {
            udpateUser();
            onClose();
        }).catch(() => {
            toast.error("Activation Request Failed! Try again!")
        });
    };

    return (
        isOpen && (
            <div className="modal-overlay">
                <div className="travel-agent-activation-modal">
                    <h2>Activate Account</h2>
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Business Name</label>
                            <input
                                type="text"
                                name="businessName"
                                value={formData.businessName}
                                onChange={handleInputChange}
                                disabled
                            />
                        </div>
                        <div className="form-group">
                            <label>Point of Contact Name</label>
                            <input
                                type="text"
                                name="pointOfContactName"
                                value={formData.pointOfContactName}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Point of Contact Number</label>
                            <input
                                type="text"
                                name="pointOfContactNumber"
                                value={formData.pointOfContactNumber}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Address</label>
                            <input
                                type="text"
                                name="address"
                                value={formData.address}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>City</label>
                            <input
                                type="text"
                                name="city"
                                value={formData.city}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>State</label>
                            <select
                                name="state"
                                value={formData.state}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a state</option>
                                {states.map((state) => (
                                    <option key={state._id} value={state._id}>
                                        {state.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label>GST Number (optional)</label>
                            <input
                                type="text"
                                name="gstin"
                                value={formData.gstin}
                                onChange={handleInputChange}
                            />
                        </div>

                        {/* Upload GST Certificate if GST Number is provided */}
                        {formData.gstin && (
                            <div className="form-group">
                                <label>Upload GST Certificate (required)</label>
                                <input
                                    type="file"
                                    name="gstCertificate"
                                    accept=".pdf,.png,.jpg,.jpeg,.gif"
                                    onChange={handleFileChange}
                                    required={formData.gstin ? true : false}
                                />
                            </div>
                        )}

                        <div className="form-group">
                            <label>Upload Business Registration Proof</label>
                            <input
                                type="file"
                                name="businessRegistrationProof"
                                accept=".pdf,.png,.jpg,.jpeg,.gif"
                                onChange={handleFileChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type="checkbox"
                                checked={formData.termsAccepted}
                                onChange={handleCheckboxChange}
                            />
                            <label>
                                I accept the Terms and Conditions and Privacy Policy
                            </label>
                        </div>

                        <button type="submit">Submit</button>
                    </form>
                    <button className="modal-close" onClick={onClose}>Close</button>
                </div>
            </div>
        )
    );
};

export default TravelAgentActivationModal;
