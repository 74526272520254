import React, { useState, useEffect } from 'react';
import { apiService } from '../../services/apiService'; // Assuming apiService for API calls
import './VisaRequirements.css';
import { FaInfoCircle } from 'react-icons/fa'; // Importing info icon

const VisaRequirements = () => {
    const [countries, setCountries] = useState([]);
    const [visaTypes, setVisaTypes] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [visaPurpose, setVisaPurpose] = useState('');
    const [selectedVisaType, setSelectedVisaType] = useState('');
    const [visaDocuments, setVisaDocuments] = useState([]);
    const [visaTypeDetails, setVisaTypeDetails] = useState(null);
    const [visaTypeCost, setVisaTypeCost] = useState(null);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false); // State for tooltip visibility

    useEffect(() => {
        // Fetch countries when the component loads
        const fetchCountries = async () => {
            try {
                const response = await apiService.get('/travelAgent/countries');
                setCountries(response.countries);
            } catch (error) {
                console.error('Error fetching countries:', error);
            }
        };
        fetchCountries();
    }, []);

    useEffect(() => {
        if (selectedCountry && visaPurpose) {
            // Fetch visa types based on selected country and visa purpose
            const fetchVisaTypes = async () => {
                try {
                    const response = await apiService.get(`/travelAgent/visa-types?countryId=${selectedCountry}&purpose=${visaPurpose}`);
                    setVisaTypes(response.visaTypes);
                } catch (error) {
                    console.error('Error fetching visa types:', error);
                }
            };
            fetchVisaTypes();
        }
    }, [selectedCountry, visaPurpose]);

    const handleVisaTypeChange = (e) => {
        setSelectedVisaType(e.target.value);
        setIsSubmitEnabled(true); // Enable submit button when visa type is selected
    };

    const handleSubmit = async () => {
        if (!selectedVisaType) return;

        try {
            const response = await apiService.get(`/travelAgent/visa-types/documents-required?visaTypeId=${selectedVisaType}`);
            setVisaDocuments(response.documents);
            setVisaTypeCost(response.visaTypeCost);
            // Fetch Visa Type details for attributes
            const visaTypeDetail = visaTypes.find(v => v._id === selectedVisaType);
            setVisaTypeDetails(visaTypeDetail);
        } catch (error) {
            console.error('Error fetching visa documents:', error);
        }
    };

    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible); // Toggle the visibility of the tooltip
    };

    return (
        <div className="visa-requirements-container">
            <h2>Visa Requirements</h2>

            <div className="form-group">
                <label htmlFor="country">Select Country:</label>
                <select
                    id="country"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    required
                >
                    <option value="">Select a country</option>
                    {countries.map((country) => (
                        <option key={country._id} value={country._id}>
                            {country.name}
                        </option>
                    ))}
                </select>
            </div>

            {/* Visa Purpose Selection */}
            <div className="form-group">
                <label>Visa Purpose:</label>
                <select
                    value={visaPurpose}
                    onChange={(e) => setVisaPurpose(e.target.value)}
                    required
                >
                    <option value="">Select purpose</option>
                    <option value="Tourist">Tourist</option>
                    <option value="Business">Business</option>
                </select>
            </div>

            {/* Visa Type Selection */}
            {visaTypes.length > 0 && (
                <div className="form-group">
                    <label htmlFor="visaType">Select Visa Type:</label>
                    <select
                        id="visaType"
                        value={selectedVisaType}
                        onChange={handleVisaTypeChange}
                        required
                    >
                        <option value="">Select a visa type</option>
                        {visaTypes.map((visaType) => (
                            <option key={visaType._id} value={visaType._id}>
                                {visaType.visaName}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {/* Submit Button */}
            {isSubmitEnabled && (
                <button className="submit-button" onClick={handleSubmit}>
                    Fetch Required Documents
                </button>
            )}

            {/* Visa Type Details */}
            {visaTypeDetails && (
                <div className="visa-details">
                    <h3>Visa Type: {visaTypeDetails.visaName}</h3>
                    <p><strong>Number of Entries:</strong> {visaTypeDetails.numberOfEntries}</p>
                    <p><strong>Visa Process Category:</strong> {visaTypeDetails.visaProcessCategory}</p>
                    <p><strong>Processing Time:</strong> {visaTypeDetails.processingTime} days</p>

                    {/* Cost to Partner with Tooltip */}
                    <p>
                        <strong>Cost to Partner:</strong> ₹{visaTypeCost.totalAmount}
                        <FaInfoCircle className="info-icon" onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} />
                        {tooltipVisible && (
                            <div className="visa-cost-tooltip">
                                <p><strong>Cost Breakdown:</strong></p>
                                <p>Visa Fees: {visaTypeCost.visaFees ? (visaTypeCost.visaFeesCurrency == 'INR' ? `₹${visaTypeCost.visaFees}` : `${visaTypeCost.visaFeesCurrency}${visaTypeCost.originalVisaFees} x ${visaTypeCost.visaFeesConversion.exchangeRate} = ₹${visaTypeCost.visaFees}`) : 'N/A'}</p>
                                <p>VAC Fees: {visaTypeCost.vacFees ? (visaTypeCost.vacFeesCurrency == 'INR' ? `₹${visaTypeCost.vacFees}` : `${visaTypeCost.vacFeesCurrency}${visaTypeCost.originalVacFees} x ${visaTypeCost.vacFeesConversion.exchangeRate} = ₹${visaTypeCost.vacFees}`) : 'N/A'}</p>
                                <p>Service Charges: ₹{visaTypeCost.serviceCharges}</p>
                                <p>GST: ₹{visaTypeCost.gstAmount || 'N/A'}</p>
                            </div>
                        )}
                    </p>
                </div>
            )}

            {/* Visa Documents */}
            {visaDocuments.length > 0 && (
                <div className="visa-documents">
                    <h3>Required Documents</h3>
                    <ul>
                        {visaDocuments.map((doc) => (
                            <li key={doc._id}>
                                <strong>{doc.documentTitle}</strong>: {doc.documentDescription} - {doc.documentMandatory ? 'Mandatory' : 'Optional'}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default VisaRequirements;
