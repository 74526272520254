import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiService } from '../../services/apiService';
import './ActiveApplications.css'; // We'll create a CSS file for styling
import { FaPlus, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import NewApplicationModal from './NewApplicationModal';
import { backendToFrontEndStatusMapping } from '../../config/constants'


const ActiveApplications = () => {
    const [applications, setApplications] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 10;
    const [totalCount, setTotalCount] = useState(0);
    const [statusFilter, setStatusFilter] = useState([]);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();

    const fetchApplications = async (page, searchQuery = '') => {
        try {
            const response = await apiService.get(`/travelAgent/active-application-groups/fetch?page=${page}&limit=${limit}&searchQuery=${searchQuery}&statusFilter=${statusFilter.join(",")}&travelDateFilterFrom=${fromDate}&travelDateFilterTo=${toDate}`);
            setApplications(response.visaApplicationGroups);
            setTotalCount(response.totalCount);
        } catch (error) {
            console.error('Error fetching applications:', error);
        }
    };

    const updatePhaseFilter = (filter) => {
        if (statusFilter.includes(filter))
            setStatusFilter(statusFilter.filter(phase => phase != filter))
        else
            setStatusFilter([filter, ...statusFilter]);
    }

    useEffect(() => {
        fetchApplications(currentPage, searchTerm);
    }, [currentPage, searchTerm]);

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to first page on search
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(totalCount / limit)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleCreateNewApplication = (applicationGroupId) => {
        navigate(`/visa-application-group/${applicationGroupId}`)
        closeModal(); // Close the modal after creation
    };

    const handleRowClick = (applicationGroupId) => {
        navigate(`/visa-application-group/${applicationGroupId}`);
    }

    return (
        <div className="main-container">
            <div className="main-header">
                <div>
                    <h2>Applications</h2>
                    {/* <div className="filters">
                        <div className="phase-filters">
                            <label>Status Filters:</label>
                            <div className="phase-filter-buttons">
                                <button onClick={() => updatePhaseFilter('submitted')} className={statusFilter.includes('submitted') ? 'active' : ''}>Submitted</button>
                                <button onClick={() => updatePhaseFilter('docsPending')} className={statusFilter.includes('docsPending') ? 'active' : ''}>Docs Pending</button>
                            </div>
                        </div>
                        <div className="travel-date-filter">
                            <label>Travel Date Filter:</label>
                            <div className="date-filter-pickers">
                                <input
                                    type="date"
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    placeholder="From"
                                />
                                to&nbsp;
                                <input
                                    type="date"
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    placeholder="To"
                                />
                            </div>
                        </div>
                        <div className="search-box">
                            <input
                                type="text"
                                placeholder="Search"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </div>
                    </div> */}
                </div>
                <button className="new-app-button" onClick={openModal}>
                    <FaPlus /> New Application
                </button>
            </div>
            <table className="data-table">
                <thead>
                    <tr>
                        <th>Order ID</th>
                        <th>Group Name</th>
                        <th>Number of Travellers</th>
                        <th>Country</th>
                        <th>Visa Type</th>
                        <th>Travel Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {applications.length > 0 ? (
                        applications.map(app => (
                            <tr key={app._id} onClick={() => handleRowClick(app._id)}>
                                <td>{app.orderNumber}</td>
                                <td>{app.groupName}</td>
                                <td>{app.numberOfTravellers}</td>
                                <td>{app.visaTypeId.countryId.name}</td>
                                <td>{app.visaTypeId.visaName}</td>
                                <td>{new Date(app.travelDateFrom).toLocaleDateString()}</td>
                                <td>{backendToFrontEndStatusMapping[app.status]}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="7" className="empty-table-td">
                                <center>
                                    <div className="no-data-message">
                                        No Active Applications
                                    </div>
                                    <button className="new-app-button" onClick={openModal}>
                                        <FaPlus /> Start An Application
                                    </button>
                                </center>
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
            <div className="pagination-controls">
                <button
                    className="pagination-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                >
                    <FaChevronLeft /> Previous
                </button>
                <span>Page {currentPage} of {Math.ceil(totalCount / limit)}</span>
                <button
                    className="pagination-button"
                    onClick={handleNextPage}
                    disabled={currentPage === Math.ceil(totalCount / limit)}
                >
                    Next <FaChevronRight />
                </button>
            </div>
            {isModalOpen && (
                <NewApplicationModal
                    onClose={closeModal}
                    onCreate={handleCreateNewApplication}
                />
            )}
        </div >
    );
};

export default ActiveApplications;