import React, { useState } from 'react';

const Travellers = () => {

    return (
        <></>
    );
};

export default Travellers;
