// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activate-account-button {
  background: linear-gradient(
    90deg,
    #00c6ff,
    #0072ff
  ); /* Gradient from light blue to darker blue */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px; /* Rounded corners for pill shape */
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-right: 10px;
}

.activate-account-button:hover {
  background: linear-gradient(
    90deg,
    #43e97b,
    #38f9d7
  ); /* Green to blue gradient on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.activate-account-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 198, 255, 0.4); /* Highlight on focus */
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE;;;;GAIC,EAAE,4CAA4C;EAC/C,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,mBAAmB,EAAE,mCAAmC;EACxD,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,wCAAwC,EAAE,0BAA0B;EACpE,4DAA4D;EAC5D,kBAAkB;AACpB;;AAEA;EACE;;;;GAIC,EAAE,oCAAoC;EACvC,yCAAyC,EAAE,6BAA6B;AAC1E;;AAEA;EACE,aAAa;EACb,4CAA4C,EAAE,uBAAuB;AACvE","sourcesContent":[".activate-account-button {\n  background: linear-gradient(\n    90deg,\n    #00c6ff,\n    #0072ff\n  ); /* Gradient from light blue to darker blue */\n  color: white;\n  border: none;\n  padding: 10px 20px;\n  border-radius: 50px; /* Rounded corners for pill shape */\n  font-size: 14px;\n  font-weight: bold;\n  cursor: pointer;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */\n  transition: background-color 0.3s ease, box-shadow 0.3s ease;\n  margin-right: 10px;\n}\n\n.activate-account-button:hover {\n  background: linear-gradient(\n    90deg,\n    #43e97b,\n    #38f9d7\n  ); /* Green to blue gradient on hover */\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */\n}\n\n.activate-account-button:focus {\n  outline: none;\n  box-shadow: 0 0 0 3px rgba(0, 198, 255, 0.4); /* Highlight on focus */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
